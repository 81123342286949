// Out of house
import React, {memo} from 'react';
import Col from 'react-bootstrap/Col'

const ServeToDash = ({children, small=[12,0], med=[8,3], large=[8,1], pageBreak=true, forceTwelve=false}) => {
    const xsSettings = large[0] === 12 && large[1] === 0 ? { span: large[0], offset: large[1] } : { span: small[0], offset: small[1] };
    const mdSettings = large[0] === 12 && large[1] === 0 ? { span: large[0], offset: large[1] } : { span: med[0], offset: med[1] };

    return (
        <>
            <div className='container-fluid mt-4 bg-secondary' style={{background:'inherit'}}>
                <div className={'row justify-content-center p-0 ' + (forceTwelve ? 'pt-2' : 'pt-5')}>
                    <h1><br/></h1>
                    <Col 
                        xs={xsSettings} 
                        md={mdSettings} 
                        lg={forceTwelve ? {span:10, offset:-4} : {span:large[0], offset:large[1]}} 
                        className={'p-0'}
                    >
                    {/* Shit for the content of dashboard goes here 😃 */}
                        {children}
                    </Col>
                </div>
            </div>
            {pageBreak && <><br/><br/></>}
        </>
    );
}
 
export default memo(ServeToDash);