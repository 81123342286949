import React, { createContext, useEffect, useState, useCallback } from "react";

// in house
import auth from "../services/authService";
import {
  getUser,
  saveSettingsHttp,
  getWatchlists,
} from "../services/userService";
import { getAssetNames, getFundsStats } from "../services/assetService";
import {transactionCodes} from '../services/otherFunctions'

const defaultTEdgeSettings = {
  fastOverSlowWeight: 20,
  adxWeight: 20,
  fastSMA: 10,
  fastWeight: 20,
  lookback: 5,
  macdWeight: 20,
  slowSMA: 40,
  slowWeight: 20,
};

export const GlobalContext = createContext({
  user: null,
  allAssetNames: [],
  saveSettings: () => {},
  handleCloseSnackbar: () => {},
  // updateStatus: () => {},
  updateWatchlists: () => {},
  widthBreak: 1200,
  snackBarMsg: "",
  snackBarSeverity: "success",
  numTotalFunds: 0,
  latestFileDt: "",
});

export const GlobalContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [allAssetNames, setAllAssetNames] = useState([]);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [fundsInfo, setFundsInfo] = useState({
    totalFunds: 0,
    latestFileDt: "",
  });
  const handleCloseSnackbar = useCallback(() => {
    setSnackBarMsg("");
    setSnackBarSeverity("success");
  }, []);

  // const [globalSnackBar, setGlobalSnackbar] = useState({open:false, message:'', severity:'success'})

  const saveSettings = useCallback(
    async (newSettings, typeName, userNotLoggedIn = false) => {
      if (userNotLoggedIn) {
        if (typeName === "showWelcomeMessage") {
          localStorage.setItem(
            "showTrendEdgeWelcome",
            JSON.stringify(newSettings.showWelcomeMessage)
          );
          setUser((curUser) => ({
            ...curUser,
            showWelcomeMessage: newSettings.showWelcomeMessage,
          }));
          return;
        }
        return;
      }
      try {
        const saveResponse = await saveSettingsHttp(
          user._id,
          newSettings,
          typeName
        ).then(async (res) => {
          const [status, msgResponse] = [res.status, res.data];
          if (status === 201) {
            if (typeName === "username")
              setUser((curUser) => ({
                ...curUser,
                name: newSettings.username,
              }));
            else if (typeName === "subList")
              setUser((curUser) => ({ ...curUser, subList: newSettings }));
            else if (typeName === "settings") {
              setUser((curUser) => ({
                ...curUser,
                settings: newSettings.settings,
              }));
            } else if (typeName === "saveScreen") {
              setUser((curUser) => {
                let newCurUser = { ...curUser };
                if (res.data.includes("updated")) {
                  newCurUser["savedScreens"] = newCurUser["savedScreens"].map(
                    (item) =>
                      item.screenName === newSettings.screenName
                        ? newSettings
                        : item
                  );
                  return newCurUser;
                }
                if (newCurUser["savedScreens"])
                  newCurUser["savedScreens"].push(newSettings);
                else newCurUser["savedScreens"] = [newSettings];
                return newCurUser;
              });
              setSnackBarMsg(msgResponse + " ✔️");
              return [status, msgResponse];
            } else if (typeName === "update_a_ScreenName") {
              if (msgResponse.includes("updated")) {
                const { oldScreenName, newScreen } = newSettings;
                const { screenName: newScreenName } = newScreen;
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  if (newCurUser.screenToLoad === oldScreenName)
                    newCurUser.screenToLoad = newScreenName;
                  newCurUser["savedScreens"] = newCurUser["savedScreens"].map(
                    (item) =>
                      item.screenName === oldScreenName ? newScreen : item
                  );
                  return newCurUser;
                });
                setSnackBarMsg(msgResponse + " ✔️");

                return [status, msgResponse];
              } else {
                console.log("something went wrong updating a screen name");
              }
            } else if (typeName === "deleteScreen") {
              const { screenName } = newSettings;
              setUser((curUser) => {
                let newCurUser = { ...curUser };
                newCurUser["savedScreens"] = newCurUser["savedScreens"].filter(
                  (item) => item.screenName !== screenName
                );
                return newCurUser;
              });
              setSnackBarMsg(msgResponse + " ✔️");
              return [status, msgResponse];
            } else if (typeName === "add_a_Watchlist") {
              if (msgResponse.includes("added")) {
                setUser((curUser) => ({
                  ...curUser,
                  watchlists: [
                    ...curUser.watchlists,
                    { name: newSettings.newWatchlistName, list: [] },
                  ],
                }));
                setSnackBarMsg(msgResponse + " ✔️");
                return [status, msgResponse];
              }
            } else if (typeName === "update_a_WatchlistName") {
              if (msgResponse.includes("updated")) {
                const { oldWatchlistName, newWatchlistName } = newSettings;
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  newCurUser["watchlists"] = newCurUser["watchlists"].map(
                    (item) =>
                      item.name === oldWatchlistName
                        ? { name: newWatchlistName, list: item.list }
                        : item
                  );
                  return newCurUser;
                });
                setSnackBarMsg(msgResponse + " ✔️");

                return [status, msgResponse];
              }
            } else if (typeName === "delete_a_Watchlist") {
              if (msgResponse.includes("removed")) {
                const { deleteWatchlistName } = newSettings;
                const { currentWatchlist } = user;
                if (deleteWatchlistName === currentWatchlist) {
                  await saveSettingsHttp(
                    user._id,
                    { currentWatchlist: "Favorites" },
                    "mount_a_Watchlist"
                  );
                }

                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  newCurUser["watchlists"] = newCurUser["watchlists"].filter(
                    (item) => item.name !== deleteWatchlistName
                  );
                  if (deleteWatchlistName === currentWatchlist) {
                    newCurUser["currentWatchlist"] = "Favorites";
                    newCurUser["currentWatchlistIndex"] = 0;
                  }
                  return newCurUser;
                });
                setSnackBarMsg(msgResponse + " ✔️");

                return [status, msgResponse];
              }
            } else if (typeName === "addTo_a_Watchlist") {
              if (msgResponse.includes("added")) {
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  const { addToThisWatchlist, newWLItem } = newSettings;
                  const watchlistIndex = newCurUser["watchlists"].findIndex(
                    (i) => i.name === addToThisWatchlist
                  );
                  newCurUser["watchlists"][watchlistIndex].list.push(newWLItem);
                  return newCurUser;
                });
                return [status, msgResponse];
              }
            } else if (typeName === "removeFrom_a_Watchlist") {
              if (msgResponse.includes("removed")) {
                const { removeFromThisWatchlist, nameToRemove } = newSettings;
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  const watchlistIndex = newCurUser["watchlists"].findIndex(
                    (i) => i.name === removeFromThisWatchlist
                  );
                  newCurUser["watchlists"][watchlistIndex].list = newCurUser[
                    "watchlists"
                  ][watchlistIndex].list.filter(
                    (item) => item.name !== nameToRemove
                  );
                  return newCurUser;
                });
                return [status, msgResponse];
              }
            } else if (typeName === "addListTo_a_Watchlist") {
              const { msg, washNum } = msgResponse;
              if (msg.includes("updating")) {
                setUser((curUser) => {
                  if (!curUser["status"]) {
                    curUser["status"] = {};
                  }
                  curUser["status"]["wash"] = washNum;
                  return curUser;
                });
                return [status, msgResponse];
              }
            } else if (typeName === "addListTo_a_WatchlistNoWash") {
              const { msg, addThese } = msgResponse;
              if (msg.includes("Added")) {
                const { addListToThisWatchlist } = newSettings;
                const watchlistIndex = user["watchlists"].findIndex(
                  (i) => i.name === addListToThisWatchlist
                );
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  newCurUser["watchlists"][watchlistIndex].list = [
                    ...newCurUser["watchlists"][watchlistIndex].list,
                    ...addThese,
                  ];
                  return newCurUser;
                });
                return [status, msg];
              }
            } else if (typeName === "mount_a_Watchlist") {
              if (msgResponse.includes("mounted")) {
                const { currentWatchlist } = newSettings;
                const watchlistIndex = user["watchlists"].findIndex(
                  (i) => i.name === currentWatchlist
                );
                setUser((curUser) => ({
                  ...curUser,
                  currentWatchlist: currentWatchlist,
                  currentWatchlistIndex: watchlistIndex,
                }));
                return [status, msgResponse];
              }
            } else if (typeName === "empty_a_Watchlist") {
              if (msgResponse.includes("cleared")) {
                const { emptyThisWatchlist } = newSettings;
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  const watchlistIndex = newCurUser["watchlists"].findIndex(
                    (i) => i.name === emptyThisWatchlist
                  );
                  newCurUser["watchlists"][watchlistIndex].list = [];
                  return newCurUser;
                });
                setSnackBarMsg(msgResponse + " ✔️");
                return [status, msgResponse];
              }
            } else if (typeName === "updateScreenToLoad") {
              if (msgResponse.includes("updated")) {
                setUser((curUser) => ({
                  ...curUser,
                  screenToLoad: newSettings.screenToLoad,
                }));
                return [status, msgResponse];
              }
            } else if (typeName === "add_a_CustomExport") {
              if (msgResponse.includes("added")) {
                const { newExport } = newSettings;
                // console.log(newExport)
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  if (newCurUser["customExports"])
                    newCurUser["customExports"].push(newExport);
                  else newCurUser["customExports"] = [newExport];
                  // console.log(newCurUser)
                  return newCurUser;
                });
                return [status, msgResponse];
              }
            } else if (typeName === "delete_a_CustomExport") {
              if (msgResponse.includes("removed")) {
                const { deleteThisCustomExportName } = newSettings;
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  newCurUser["customExports"] = newCurUser[
                    "customExports"
                  ].filter((item) => item.name !== deleteThisCustomExportName);
                  return newCurUser;
                });
                setSnackBarMsg(msgResponse + " ✔️");
                return [status, msgResponse];
              }
            } else if (typeName === "update_a_CustomExportName") {
              if (msgResponse.includes("updated")) {
                const { oldExportName, newExportName } = newSettings;
                setUser((curUser) => {
                  let newCurUser = { ...curUser };
                  newCurUser["customExports"] = newCurUser["customExports"].map(
                    (item) =>
                      item.name === oldExportName
                        ? { ...item, name: newExportName }
                        : item
                  );
                  return newCurUser;
                });
                setSnackBarMsg(msgResponse + " ✔️");

                return [status, msgResponse];
              }
            } else if (typeName === "updateBatchExportList") {
              if (msgResponse.includes("updated")) {
                setUser((curUser) => ({
                  ...curUser,
                  batchExportList: newSettings.batchExportList,
                }));
                return [status, msgResponse];
              }
            } else if (typeName === "livePriceTabbing") {
              if (msgResponse.includes("updated")) {
                setUser((curUser) => ({
                  ...curUser,
                  livePriceTabbing: newSettings.livePriceTabbing,
                }));
                return [status, msgResponse];
              }
            } else if (typeName === "showWelcomeMessage") {
              if (msgResponse.includes("updated")) {
                setUser((curUser) => ({
                  ...curUser,
                  showWelcomeMessage: newSettings.showWelcomeMessage,
                }));
                return [status, msgResponse];
              }
            } else if (typeName === "submitScreenOnLoad") {
              if (msgResponse.includes("updated")) {
                setUser((curUser) => ({
                  ...curUser,
                  submitScreenOnLoad: newSettings.submitScreenOnLoad,
                }));
                return [status, msgResponse];
              }
            } else if (typeName === "showQuickChartOnHover") {
              if (msgResponse.includes("updated")) {
                setUser((curUser) => ({
                  ...curUser,
                  showQuickChartOnHover: newSettings.showQuickChartOnHover,
                }));
                return [status, msgResponse];
              }
            } else if (typeName === "updateNotes") {
              if (msgResponse.includes("updated")) {
                const { name, note, date } = newSettings;

                const noteIndex = user.notes.findIndex(
                  (item) => item.name === name
                );
                const newNotes = [...user.notes];
                if (noteIndex !== -1) {
                  newNotes[noteIndex].note = note;
                  newNotes[noteIndex].date = date;
                } else {
                  newNotes.push(newSettings);
                }
                setUser((curUser) => ({
                  ...curUser,
                  notes: newNotes,
                }));
                return [status, msgResponse];
              }
            } else if (typeName === "updateInsiderTransaxFilters") {
              if (msgResponse.includes("updated")) {
                setUser((curUser) => ({
                  ...curUser,
                  insiderTransaxFilters: newSettings
                }));
                return [status, msgResponse];
              }
            }
          }
          return res;
        });
        return saveResponse;
      } catch (e) {
        console.log(e);
        const [status, msgResponse] = [e.response.status, e.response.data];
        if (msgResponse.msg) setSnackBarMsg(msgResponse.msg + " ⚠️");
        else setSnackBarMsg(msgResponse + " ⚠️");
        setSnackBarSeverity("error");
        // if (status === 401) {
        //   auth.logout()
        //   window.location = '/login'
        // }
        if (status === 400) return [status, msgResponse];
      }
    },
    [user]
  );

  useEffect(() => {
    const mountApp = async () => {
      const userData = auth.getCurrentUser();
      const showWelcomeMessage = localStorage.getItem("showTrendEdgeWelcome");
      let user = {
        _id: false,
        name: false,
        settings: defaultTEdgeSettings,
        watchlists: [{ name: "Favorites", list: [] }],
        currentWatchlist: "Favorites",
        savedScreens: [],
        status: {},
        livePriceTabbing: false,
        submitScreenOnLoad: true,
        showQuickChartOnHover: false,
        insiderTransaxFilters:Object.keys(transactionCodes)
      };
      user.showWelcomeMessage = showWelcomeMessage
        ? JSON.parse(showWelcomeMessage)
        : true;

      user["gPass"] = false;
      if (userData) {
        user = await getUser(userData._id);
        user["gPass"] = userData.gPass;
      }

      if (!user.notes) user.notes = [];

      let currentWatchlistIndex = user["watchlists"].findIndex(
        (i) => i.name === user.currentWatchlist
      );
      if (currentWatchlistIndex === -1) currentWatchlistIndex = 0;
      const { data } = await getAssetNames();
      const { data: fStats } = await getFundsStats();
      const { numTotalFunds, latestFileDt } = fStats;
      setFundsInfo({ numTotalFunds, latestFileDt });
      setAllAssetNames(data);
      setUser({ ...user, currentWatchlistIndex });
    };
    mountApp();
  }, []);

  const updateWatchlists = useCallback(async () => {
    const data = await getWatchlists(user._id);
    // console.log(watchlists)
    const { watchlists, status } = data;
    setUser((curUser) => ({ ...curUser, watchlists, status }));
  }, [user._id]);

  // const updateStatus = useCallback(async () => {
  //   const status = await getStatus(user._id);
  //   setUser((curUser) => ({ ...curUser, status }));
  // }, [user._id]);

  const globalContextValue = {
    user,
    allAssetNames,
    saveSettings,
    handleCloseSnackbar,
    snackBarMsg,
    snackBarSeverity,
    widthBreak: 1200,
    // updateStatus,
    updateWatchlists,
    fundsInfo,
  };

  return (
    <GlobalContext.Provider value={globalContextValue}>
      {children}
    </GlobalContext.Provider>
  );
};
