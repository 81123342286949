import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function setJwt(jwt) {
  axios.defaults.headers.common['x-auth-token'] = jwt;
}

function get(url, config = {}) {
  const axiosConfig = { ...config };
  if (config.baseUrl) {
    console.log('config.baseUrl', config.baseUrl)
    axiosConfig.baseURL = config.baseUrl;
    delete axiosConfig.baseUrl;
  }
  return axios.get(url, axiosConfig);
}

function post(url, data, config = {}) {
  const axiosConfig = { ...config };
  if (config.baseUrl) {
    axiosConfig.baseURL = config.baseUrl;
    delete axiosConfig.baseUrl;
  }
  return axios.post(url, data, axiosConfig);
}

function put(url, data, config = {}) {
  const axiosConfig = { ...config };
  if (config.baseUrl) {
    axiosConfig.baseURL = config.baseUrl;
    delete axiosConfig.baseUrl;
  }
  return axios.put(url, data, axiosConfig);
}

function del(url, config = {}) {
  const axiosConfig = { ...config };
  if (config.baseUrl) {
    axiosConfig.baseURL = config.baseUrl;
    delete axiosConfig.baseUrl;
  }
  return axios.delete(url, axiosConfig);
}

const httpExport = {
  get,
  post,
  put,
  delete: del,
  setJwt
};

export default httpExport;